import { forwardRef, InputHTMLAttributes } from "react";

import { cn } from "~/utilities/cn";

export type TInputVariants = "contained" | "outlined";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  variant?: TInputVariants;
}

export type Ref = HTMLInputElement;

const commonClass = "focus:outline-none";

const _inputVariant: Record<TInputVariants, string> = {
  contained: "w-full rounded-xl bg-white px-3 py-1 text-color",
  outlined: "border border-darkGrey25 rounded-3xl px-3 py-[18px]",
};

const Input = forwardRef<Ref, InputProps>(
  ({ variant = "contained", ...restProps }, ref) => {
    return (
      <input
        ref={ref}
        {...restProps}
        className={cn(
          _inputVariant[variant],
          commonClass,
          "placeholder-custom",
          restProps.className
        )}
      />
    );
  }
);

Input.displayName = "Input";

export default Input;
